.dropdown-select-toggle {
  cursor: pointer;
  border: 1px solid transparent;
  height: 49px;
  padding-right: 25px;
  color: var(--text-key);
  background-color: var(--input-on-surface);
}

.dropdown-item {
  background-color: var(--surface-panel);
  color: var(--text-key);
}
.dropdown-menu {
  background-color: var(--surface-panel);
  color: var(--text-key);
}

.dropdown-primary-bg-arrow {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.dropdown-select-toggle:hover {
  border: 1px solid var(--accent-03);
}
