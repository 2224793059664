:root .dark-theme {
  --primary-color: #0080FF;
  --primary-pale-color: #064584;
  --surface-color: #22272e;
  --on-surface-color: #e6f0ff;
  --input-on-background: #2e3f57;
  --background-color: #2b3441;
  --text-color: #E6F0FF; 
}

:root .light-theme {
  --primary-color: #0080FF;
  --primary-pale-color: #DCECF5;
  --text-color: #3B475D;
}
