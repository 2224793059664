.radio-btn-group {
  display: flex;
  align-items: center;
}

.radio-btn-margin {
  margin-top: var(--space-06);
}

.label-text {
  margin-left: var(--space-06);
}
