.status-wrapper {
  display: inline-flex;
  padding: var(--space-03) var(--space-06) var(--space-03) var(--space-04);
  align-items: center;
  gap: var(--space-03);
  border-radius: var(--radius-04);
  cursor: default;

  &.stat-wrapper-green {
    background: var(--surface-contrast-alpha-success);
    color: var(--text-contrast-success);
    fill: var(--text-contrast-success);
  }

  &.stat-wrapper-yellow {
    background: var(--surface-contrast-alpha-warning);
    color: var(--text-contrast-warning);
    fill: var(--text-contrast-warning);
  }

  &.stat-wrapper-red {
    background: var(--surface-contrast-alpha-danger);
    color: var(--text-contrast-danger);
    fill: var(--text-contrast-danger);
  }

  &.stat-wrapper-gray {
    background: var(--surface-contrast-alpha-neutral);
    color: var(--text-primary);
    fill: var(--text-primary);
  }

  .status-icon {
    width: 16px;
    height: 16px;
  }
}
