.dark-theme-switch-container {
  position: relative;
  cursor: pointer;
  background-color: var(--surface-color);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.5s linear;
  box-shadow: 0px 3px 6px #6a717e4d;
  span {
    z-index: 2;
  }
  .dark-theme-switch-overlay {
    background-color: var(--surface-color);
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100px;
    height: 40px;
    border-radius: 50px;
    box-shadow: 0px 3px 6px #6a717e4d;

    &.hide {
      display: none;
    }
    &.leftPosition {
      transform: translateX(-50px);
    }
    &.rightPosition {
      transform: translateX(50px);
    }
    &.lightshadow {
      box-shadow: 5px 5px 5px #4f7fde2e;
    }
  }
  &.expanded {
    background-color: #f5f6f8;
    width: 200px;
    height: 40px;
  }
  .theme-switch {
    height: 24px;
    width: 24px;
  }
  .sun-switch-side {
    display: flex;
    color: var(--accent-03);
    &.hide {
      display: none;
    }
    // &.shade {
    //   color: #769dd4;
    // }
  }

  .dark-switch-side {
    display: flex;
    color: var(--text-color);
    &.hide {
      display: none;
    }
    &.shade {
      color: #aeb5c9;
    }
    &.marginLeft {
      margin-left: 30px;
    }
  }
}

body.dark-theme {
  .dark-theme-switch-container {
    &.expanded {
      background-color: var(--accent-03-a);
    }
  }
}

.theme-switch-container {
  display: flex;
  justify-content: space-between;
  gap: var(--space-06);
  padding: var(--space-04) 0 var(--space-04) var(--space-06);
}
