.custom-menu-wrapper {
  .list-unstyled {
    max-height: 250px;
    overflow: auto;
  }
}

.list-unstyled {
  max-height: 250px;
  overflow: auto;
}
