@import url("https://fonts.googleapis.com/css2?family=Fragment+Mono&display=swap");

@font-face {
  font-family: "Local Inter";
  src: url("/static/css/fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
}

:root {
  --radius-01: 4px;
  --radius-02: 8px;
  --radius-03: 12px;
  --radius-04: 16px;

  --space-01: 2px;
  --space-02: 4px;
  --space-03: 6px;
  --space-04: 8px;
  --space-05: 10px;
  --space-06: 12px;
  --space-07: 16px;
  --space-08: 20px;
  --space-09: 24px;
  --space-10: 28px;
  --space-11: 32px;
  --space-12: 36px;
  --space-13: 40px;
  --space-14: 44px;
  --space-15: 48px;
  --space-16: 64px;
  --space-17: 72px;
  --space-18: 128px;
  --space-19: 192px;

  --border-01: 1px;
  --border-02: 2px;

  --h1-font-size: 20px;
  --h1-line-height: 40px;
  --h1-font-weight: 550;

  --h2-font-size: 20px;
  --h2-line-height: 40px;
  --h2-font-weight: 400;

  --h3-font-size: 16px;
  --h3-line-height: 20px;
  --h3-font-weight: 550;

  --h4-font-size: 16px;
  --h4-line-height: 20px;
  --h4-font-weight: 450;

  --large-font-size: 14px;
  --large-line-height: 20px;
  --large-font-weight: 450;
  --large-italic-font-style: italic;
  --large-bold-font-weight: 550;

  --small-font-size: 12px;
  --small-line-height: 16px;
  --small-font-weight: 450;
  --small-italic-font-style: italic;
  --small-bold-font-weight: 550;

  --code-font-size: 14px;
  --code-line-height: 20px;
  --code-font-weight: 400;
}

.inter-font {
  font-family: "Local Inter", sans-serif;
  -webkit-font-feature-settings: "case" on, "cv11" on, "cv06" on, "cv05" on;
  -moz-font-feature-settings: "case" on, "cv11" on, "cv06" on, "cv05" on;
  -ms-font-feature-settings: "case" on, "cv11" on, "cv06" on, "cv05" on;
  font-feature-settings: "case" on, "cv11" on, "cv06" on, "cv05" on;
  font-style: normal;
  color: var(--text-key);
}

.fragment-mono-font {
  font-family: "Fragment Mono", monospace;
  -webkit-font-feature-settings: "case" on;
  -moz-font-feature-settings: "case" on;
  -ms-font-feature-settings: "case" on;
  font-feature-settings: "case" on;
  font-style: normal;
  color: var(--text-key);
}

h1 {
  @extend .inter-font;
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height) !important;
  font-weight: var(--h1-font-weight);
}

h2 {
  @extend .inter-font;
  font-size: var(--h2-font-size) !important;
  line-height: var(--h2-line-height);
  font-weight: var(--h2-font-weight);
}

h3 {
  @extend .inter-font;
  font-size: var(--h3-font-size) !important;
  line-height: var(--h3-line-height);
  font-weight: var(--h3-font-weight);
}

h4 {
  @extend .inter-font;
  font-size: var(--h4-font-size) !important;
  line-height: var(--h4-line-height);
  font-weight: var(--h4-font-weight);
}

.large {
  @extend .inter-font;
  font-size: var(--large-font-size) !important;
  line-height: var(--large-line-height) !important;
  font-weight: var(--large-font-weight) !important;
}

.large-italic {
  @extend .large;
  font-style: var(--large-italic-font-style);
}

.large-bold {
  @extend .large;
  font-weight: var(--large-bold-font-weight) !important;
}

.small {
  @extend .inter-font;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  font-weight: var(--small-font-weight);
}

.small-italic {
  @extend .small;
  font-style: var(--small-italic-font-style);
}

.small-bold {
  @extend .small;
  font-weight: var(--small-bold-font-weight);
}

.small-description {
  @extend .small;
  color: var(--text-primary);
}

.code {
  @extend .fragment-mono-font;
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  font-weight: var(--code-font-weight);
}
