.footer-wrapper {
  display: flex;
  width: 100%;
  padding: var(--space-07) var(--space-11);
  justify-content: flex-end;
  align-items: center;
  gap: var(--space-06);
  background: var(--surface-panel);

  .footer-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;

    .footer-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: var(--space-06);
    }
  }

  &.progress-large {
    padding: var(--space-07) var(--space-15) var(--space-15) var(--space-15);
    justify-content: center;

    .footer-actions {
      max-width: 1344px;

      .footer-right {
        flex: 1 0 0;
      }
    }
  }

  &.bottom-radius {
    border-bottom-left-radius: var(--radius-02);
    border-bottom-right-radius: var(--radius-02);
  }

  &.no-padding {
    padding: 0;
  }
}
