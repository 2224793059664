.loader-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  &.fullWidth {
    width: 100%;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  .loader-icon-large {
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;

    svg {
      fill: var(--object-contrast-brand);
    }
  }

  .loader-icon-small {
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;

    &.black {
      svg {
        path {
          fill: var(--object-key);
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
