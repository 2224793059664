.overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background: var(--surface-background);

  .overlay-header {
    display: flex;
    padding: var(--space-07) var(--space-15);
    flex-direction: column;
    align-items: center;
    gap: var(--space-07);
    align-self: stretch;
    background: var(--surface-panel);

    &-linkedin-stream-page {
      z-index: 20000;

      h1 {
        max-width: 1280px;
      }
    }

    h1 {
      width: 100%;
      max-width: 1344px;
    }

    .stepper-container {
      max-width: 1344px;
      width: 100%;
    }

    .overlay-tab-bar {
      display: flex;
      height: 40px;
      max-width: 1344px;
      align-items: flex-start;
      gap: var(--space-04);
      align-self: stretch;
      margin: auto;
      width: 100%;
    }
  }

  .overlay-content {
    display: flex;
    padding: var(--space-15) var(--space-15) var(--space-17) var(--space-15);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-11);
    flex: 1 0 0;
    align-self: stretch;
    overflow-y: auto;

    &-sequence-page {
      padding: var(--space-15);
      overflow-y: hidden;
    }

    &-linkedin-stream-page {
      position: relative;
      top: -87px;
      padding: 0 !important;
    }

    .overlay-container {
      display: flex;
      max-width: 1344px;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--space-11);
      align-self: stretch;
      margin: 0 auto;
      width: 100%;

      &-sequence-page {
        height: calc(100vh - 132px - 104px - 2 * var(--space-15));
      }

      &-edit-page-sequence {
        height: calc(100vh - 144px - 104px - 2 * var(--space-15));
      }

      .setting {
        display: flex;
        align-items: flex-start;
        gap: var(--space-11);
        align-self: stretch;
        margin-top: 0px;

        .name-and-description {
          display: flex;
          width: 320px;
          max-width: 480px;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--space-02);

          span {
            width: 100%;

            &.small {
              color: var(--text-primary);
            }
          }
        }

        .control {
          display: flex;
          max-width: 480px;
          align-items: center;
          gap: var(--space-06);
          flex: 1 0 0;
        }

        .campaign-type-tiles {
          display: flex;
          max-width: 480px;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--space-04);
          flex: 1 0 0;

          .campaign-type-row {
            display: flex;
            align-items: flex-start;
            gap: var(--space-04);
          }
        }

        &.degree-of-connection {
          position: relative;
          width: fit-content;

          &.basic {
            bottom: 162px;
          }

          &.sales-nav {
            bottom: 198px;
          }
        }
      }
    }
  }

  .email-settings-warning-message-wrapper {
    width: 100%;
    max-width: 1338px;

    .email-settings-content {
      width: fit-content;
    }
  }
}

.overlay .overlay-content .loader {
  display: flex;
  align-self: center;
  width: auto;
}

.overlay-content-statistic-page.overlay-content {
  margin-top: var(--space-15);
  padding: 0 var(--space-15) var(--space-17) var(--space-15);
}
