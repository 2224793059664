.stepper-container {
  display: flex;
  max-width: 1584px;
  align-items: center;
  gap: var(--space-06);

  .stepper-item-wrapper {
    display: flex;
    align-items: center;
  }

  .stepper-divider {
    flex: 1;
    background: var(--outline-secondary);
    height: 1px;
  }

  .stepper-divider-completed {
    svg {
      stroke: var(--outline-key);
    }
  }
}
