.countdown-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("/images/ci-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #fff;
  }

  .countdown-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    border: 2px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.1); // Slightly see-through white
    backdrop-filter: blur(5px);

    .circular-timer-wrapper {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 200px;
      margin: 15px;

      p {
        font-size: 24px;
        margin-top: 10px;
      }

      .circular-timer {
        &.CircularProgressbar .CircularProgressbar-text {
          fill: var(--accent-03);
          margin-top: 20px;
        }

        &.CircularProgressbar .CircularProgressbar-trail {
          stroke: var(--accent-03-a);
        }

        &.CircularProgressbar .CircularProgressbar-path {
          stroke: var(--accent-03);
          transition: stroke-dashoffset 0.5s ease 0s;
        }
      }
    }
  }
}