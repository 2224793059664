.prompt-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11002;
  background-color: rgba(0, 0, 0, 0.5);

  &.hide {
    display: none;
  }
}

.prompt-container {
  width: 544px;
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: var(--surface-panel);
  border-radius: var(--radius-02);
  outline-offset: -1px;
  cursor: default;

  .rectangle {
    width: 8px;
    height: 100%;
    position: absolute;
    border-radius: var(--radius-02) 0 0 var(--radius-02);
    top: 0;
    left: 0;
  }

  .title {
    padding: var(--space-07) var(--space-07) var(--space-07) var(--space-11);
    color: var(--text-key);
  }

  .text-wrapper {
    display: flex;
    padding: var(--space-07) var(--space-11) var(--space-11) var(--space-11);
    align-items: flex-start;
    gap: var(--space-06);
    align-self: stretch;

    .icon {
      padding: var(--space-03);

      svg {
        width: 28px;
        height: 28px;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 0;
      align-self: stretch;
      color: var(--text-key);
    }
  }

  .footer {
    width: 544px;
  }

  &.info {
    .text-wrapper {
      svg {
        fill: var(--object-contrast-brand);
      }
    }
    .rectangle {
      background: var(--surface-contrast-brand);
    }
  }

  &.success {
    .text-wrapper {
      svg {
        fill: var(--object-contrast-success);
      }
    }
    .rectangle {
      background: var(--surface-contrast-success);
    }
  }

  &.warning {
    .text-wrapper {
      svg {
        fill: var(--object-contrast-warning);
      }
    }
    .rectangle {
      background: var(--surface-contrast-warning);
    }
  }

  &.error {
    .text-wrapper {
      svg {
        fill: var(--object-contrast-danger);
      }
    }
    .rectangle {
      background: var(--surface-contrast-danger);
    }
  }
}
