.loading-page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000;
  margin: 0;
  background-color: var(--surface-scrim-invert);
}

.loading-page-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
