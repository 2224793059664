:root {
  --white: rgba(255, 255, 255, 1);
  --gray-010: rgba(248, 249, 250, 1);
  --gray-020: rgba(242, 243, 245, 1);
  --gray-030: rgba(237, 238, 240, 1);
  --gray-040: rgba(220, 221, 224, 1);
  --gray-050: rgba(177, 180, 186, 1);
  --gray-060: rgba(134, 138, 147, 1);
  --gray-070: rgba(90, 95, 104, 1);
  --gray-080: rgba(66, 70, 78, 1);
  --gray-090: rgba(41, 45, 52, 1);
  --gray-100: rgba(32, 37, 46, 1);
  --gray-110: rgba(25, 30, 39, 1);
  --gray-120: rgba(18, 22, 28, 1);
  --black: rgba(13, 16, 20, 1);
  --white-a: rgba(255, 255, 255, 0.12);
  --white-b: rgba(255, 255, 255, 0.16);
  --white-s: rgba(255, 255, 255, 0.75);
  --gray-030-a: rgba(237, 238, 240, 0.12);
  --gray-080-a: rgba(66, 70, 78, 0.08);
  --black-a: rgba(14, 17, 23, 0.08);
  --black-b: rgba(14, 17, 23, 0.12);
  --black-s: rgba(0, 0, 0, 0.4);
  --accent-01: rgba(102, 179, 255, 1);
  --accent-01-a: rgba(102, 179, 255, 0.24);
  --accent-02: rgba(51, 153, 255, 1);
  --accent-03: rgba(0, 128, 255, 1);
  --accent-03-a: rgba(0, 128, 255, 0.16);
  --accent-04: rgba(0, 102, 204, 1);
  --accent-05: rgba(0, 77, 153, 1);
  --green-01: rgba(112, 229, 106, 1);
  --green-01-a: rgba(112, 229, 106, 0.24);
  --green-03: rgba(43, 155, 37, 1);
  --green-03-a: rgba(43, 155, 37, 0.16);
  --yellow-01: rgba(218, 166, 94, 1);
  --yellow-01-a: rgba(218, 166, 94, 0.24);
  --yellow-03: rgba(194, 113, 0, 1);
  --yellow-03-a: rgba(194, 113, 0, 0.16);
  --red-01: rgba(249, 111, 117, 1);
  --red-01-a: rgba(249, 111, 117, 0.24);
  --red-02: rgba(247, 75, 83, 1);
  --red-03: rgba(203, 60, 67, 1);
  --red-03-a: rgba(203, 60, 67, 0.16);
  --red-04: rgba(158, 45, 51, 1);
  --red-05: rgba(114, 31, 34, 1);
  --lapis-01: rgba(36, 73, 208, 1);
  --lapis-02: rgba(142, 167, 255, 1);
  --bronze-01: rgba(192, 132, 0, 1);
  --bronze-02: rgba(255, 197, 69, 1);
  --jade-01: rgba(109, 159, 8, 1);
  --jade-02: rgba(169, 231, 46, 1);
  --violet-01: rgba(136, 50, 154, 1);
  --violet-02: rgba(229, 144, 248, 1);
  --chestnut-01: rgba(169, 63, 34, 1);
  --chestnut-02: rgba(255, 121, 85, 1);
  --maroon-01: rgba(157, 40, 91, 1);
  --maroon-02: rgba(255, 118, 178, 1);
  --lagoon-01: rgba(28, 128, 145, 1);
  --lagoon-02: rgba(85, 207, 227, 1);
  --blush-01: rgba(130, 96, 67, 1);
  --blush-02: rgba(228, 177, 134, 1);
  --quartz-01: rgba(76, 90, 114, 1);
  --quartz-02: rgba(149, 165, 194, 1);
  --slate-01: rgba(79, 112, 107, 1);
  --slate-02: rgba(164, 192, 188, 1);
}
