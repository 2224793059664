.button-container {
  display: flex;
  align-items: center;

  .button {
    display: flex;
    padding: var(--space-05);
    border: none;
    border-radius: var(--radius-02);
    cursor: pointer;
    outline-offset: calc(-1 * var(--border-01));

    .button-text {
      display: flex;
    }

    .button-icon {
      display: flex;
      height: 20px;
      width: auto;
    }

    &__primary {
      background-color: var(--surface-contrast-brand);

      .button-text {
        color: var(--text-invert);
      }
      .button-icon {
        fill: var(--object-invert);
      }

      &:hover:enabled {
        background-color: var(--surface-contrast-hover-brand);
      }
      &:active:enabled {
        background-color: var(--surface-contrast-active-brand);
      }
      &:disabled {
        background-color: var(--surface-tertiary);
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }

    &__danger {
      background-color: var(--surface-contrast-danger);

      .button-text {
        color: var(--text-invert);
      }
      .button-icon {
        fill: var(--object-invert);
      }

      &:hover:enabled {
        background-color: var(--surface-contrast-hover-danger);
      }
      &:active:enabled {
        background-color: var(--surface-contrast-active-danger);
      }
      &:disabled {
        background-color: var(--surface-tertiary);
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }

    &__secondary {
      background-color: transparent;
      outline: var(--border-01) solid !important;
      outline-color: var(--outline-key) !important;

      .button-text {
        color: var(--text-key);
      }
      .button-icon {
        fill: var(--object-key);
      }

      &:hover:enabled {
        background-color: var(--surface-hover);
      }
      &:active:enabled {
        background-color: var(--surface-active);
      }
      &:disabled {
        outline-color: var(--outline-tertiary) !important;
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }

    &__danger-secondary {
      background-color: transparent;
      outline: var(--border-01) solid !important;
      outline-color: var(--outline-contrast-danger) !important;

      .button-text {
        color: var(--text-contrast-danger);
      }
      .button-icon {
        fill: var(--object-contrast-danger);
      }

      &:hover:enabled {
        background-color: var(--surface-hover);
      }
      &:active:enabled {
        background-color: var(--surface-contrast-alpha-danger);
      }
      &:disabled {
        outline-color: var(--outline-tertiary) !important;
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }

    &__ghost {
      background-color: transparent;

      .button-text {
        color: var(--text-key);
      }
      .button-icon {
        fill: var(--object-key);
      }

      &:hover:enabled {
        background-color: var(--surface-hover);
      }
      &:active:enabled {
        background-color: var(--surface-active);
      }
      &:disabled {
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }

    &__danger-ghost {
      background-color: transparent;

      .button-text {
        color: var(--text-contrast-danger);
      }
      .button-icon {
        fill: var(--object-contrast-danger);
      }

      &:hover:enabled {
        background-color: var(--surface-hover);
      }
      &:active:enabled {
        background-color: var(--surface-contrast-alpha-danger);
      }
      &:disabled {
        cursor: not-allowed;

        .button-text {
          color: var(--text-secondary);
        }
        .button-icon {
          fill: var(--object-secondary);
        }
      }
    }
  }

  .text-min-width {
    min-width: 48px;
    justify-content: center;
  }

  .text-padding-01 {
    padding: 0;
  }

  .text-padding-02 {
    padding-left: var(--space-04);
  }

  .text-padding-03 {
    padding-left: var(--space-04);
    padding-right: var(--space-04);
  }

  .btn-padding-01 {
    padding: var(--space-05) var(--space-04);
  }

  .btn-padding-02 {
    padding: var(--space-05) var(--space-07) var(--space-05) var(--space-04);
  }

  .btn-padding-03 {
    padding: var(--space-05);
  }

  .btn-padding-04 {
    padding: var(--space-05) var(--space-06);
  }
}

.button-small-loader {
  margin-left: var(--space-06);
}
