.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  padding: var(--space-16) 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11001;
  background-color: var(--surface-scrim);

  &.hide {
    display: none;
  }
}

.modal-container .header.header-blue .title,
.modal-container .header.header-red .title,
.modal-container .header.header-orange .title,
.modal-container .header.header-green .title {
  color: var(--text-invert);
}

.modal-container {
  position: relative;
  background-color: var(--surface-panel);
  border-radius: var(--radius-02);
  cursor: default;

  .header {
    display: flex;
    padding: var(--space-07) var(--space-11);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-07);
    align-self: stretch;
    border-top-left-radius: var(--radius-02);
    border-top-right-radius: var(--radius-02);

    &.header-blue {
      background: var(--surface-contrast-brand);
    }

    &.header-red {
      background: var(--surface-contrast-danger);
    }

    &.header-green {
      background: var(--surface-contrast-warning);
    }

    &.header-orange {
      background: var(--surface-contrast-success);
    }
  }

  &.small {
    width: 544px;
  }

  &.large {
    width: 864px;
  }

  .title {
    color: var(--text-key);
  }

  .title-wrapper {
    width: 100%;
    color: var(--text-key);

    .stepper-container {
      width: 100%;
    }
  }

  .content {
    padding: var(--space-11) var(--space-11) var(--space-15) var(--space-11);
    background-color: var(--surface-background);
    max-height: calc(100vh - 2 * 72px - 2 * var(--space-16));
    overflow: auto;
  }

  .content-without-padding {
    padding: 0;
    border-bottom-left-radius: var(--radius-02);
    border-bottom-right-radius: var(--radius-02);
    overflow: hidden;
  }
}
