.content-switcher-row {
  display: inline-flex;
  align-items: center;
  gap: var(--space-01);

  :first-child {
    border-top-left-radius: var(--radius-02);
    border-bottom-left-radius: var(--radius-02);
  }

  :last-child {
    border-top-right-radius: var(--radius-02);
    border-bottom-right-radius: var(--radius-02);
  }
}