.stepper-item-container {
  display: flex;
  align-items: center;
  margin: var(--space-04) 0;

  .icon-wrapper {
    display: flex;
    width: 28px;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    border: var(--border-01) solid var(--outline-secondary);

    svg {
      width: 20px;
      height: 20px;
      fill: var(--object-primary);
    }
  }

  .item-label {
    display: inline-block;
    white-space: nowrap;
    margin-left: var(--space-06);
    color: var(--text-primary);
    cursor: default;
  }
}

.step-item-active {
  .icon-wrapper {
    background-color: var(--surface-contrast-brand);
    border-color: var(--surface-contrast-brand);

    svg {
      fill: var(--object-invert);
    }
  }

  .item-label {
    color: var(--text-key);
  }
}

.step-item-completed {
  .icon-wrapper {
    border-color: var(--outline-key);

    svg {
      fill: var(--object-key);
    }
  }

  .item-label {
    color: var(--text-key);
  }
}
