.search-container {
  display: flex;
  position: relative;
  align-items: center;
  gap: var(--space-04);
  border-radius: var(--radius-02);
  background: var(--surface-primary);
  padding-left: var(--space-05);

  .search-icon {
    fill: var(--object-secondary);
    position: initial;
    margin-top: var(--space-08);
  }

  &__clear-search {
    display: flex;
    max-width: 444px;
    align-items: center;
    gap: var(--space-06);
    align-self: stretch;

    svg.clear-search-icon {
      path {
        stroke: var(--object-key);
      }
    }
  }

  span {
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__input {
    width: 100%;
    color: var(--object-key);
    padding: var(--space-05) var(--space-06) var(--space-05) 0;

    &:placeholder {
      color: var(--text-secondary);
    }
  }

  &:hover {
    outline: var(--border-01) solid var(--outline-tertiary);
    border-radius: var(--radius-02);
    background: var(--surface-primary);
    outline-offset: -1px;

    svg {
      path:nth-of-type(1) {
        fill: var(--object-secondary);
      }
    }
  }

  &:focus-within {
    outline: var(--border-02) solid var(--outline-contrast-brand);
    border-radius: var(--radius-02);
    background: var(--surface-primary);
    outline-offset: -2px;

    svg {
      path:nth-of-type(1) {
        fill: var(--object-key);
      }
    }
  }
}
