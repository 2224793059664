.whitelabel-widget-wrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 10px;
  left: 100px;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 5px;
  height: 110px;
}
