.content-switcher-item-wrapper {
  display: flex;
  padding: var(--space-06);
  width: fit-content;
  height: fit-content;
  align-items: center;
  color: var(--text-primary);
  background: var(--surface-secondary);
  cursor: pointer;

  &.content-switcher-item-with-text {
    padding: var(--space-05) var(--space-06);
  }

  &.content-switcher-item-with-icon {
    padding: var(--space-05) !important;
  }

  &.content-switcher-item-disbled {
    cursor: not-allowed;
  }

  &.content-switcher-item-stretched {
    min-width: 64px;
  }

  &:hover {
    color: var(--text-key);

    .content-switcher-item-icon {
      fill: var(--object-key) !important;
    }
  }

  &.content-switcher-item-active {
    background: var(--surface-contrast-alpha-brand);
    color: var(--text-key);
  }

  .content-switcher-item-icon-wrapper {
    width: 20px;
    height: 20px;

    .content-switcher-item-icon {
      width: 20px;
      height: 20px;
      fill: var(--object-primary);

      &.content-switcher-item-icon-active {
        fill: var(--object-key);
      }
    }
  }
}
