.edit-email-modal-section-wrapper {
  display: flex;
  gap: var(--space-11);
  margin-bottom: var(--space-11);

  &__left {
    width: 320px;
  }

  &__right {
    flex: 1;
  }
}

.without-bottom-margin {
  margin-bottom: 0;
}
