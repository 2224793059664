.integration-message {
  display: flex;
  align-items: center;
  gap: var(--space-04);

  span {
    display: flex;
  }

  &__error-text {
    color: var(--object-contrast-danger);
  }

  &__success-text {
    color: var(--object-contrast-success);
  }

  &__error-icon {
    width: 16px;
    height: 16px;
    fill: var(--object-contrast-danger);
  }

  &__success-icon {
    width: 16px;
    height: 16px;
    fill: var(--object-contrast-success);
  }
}

.buttons-container {
  margin-left: 27px;
  display: flex;
  justify-content: center;
}

.disabled-sender-email {
  .form-control {
    background-color: var(--border-color) !important;
  }

  .input-group {
    background-color: var(--border-color) !important;
    opacity: 0.6;
  }
}

.integration-body-section-right {
  display: flex;
  flex-direction: column;
  gap: var(--space-07);
}
