.help-center-wrapper {
  display: flex;
  align-items: center;

  .help-center-tooltip-label {
    color: var(--text-contrast-brand);
  }

  .help-center-icon {
    width: 16px;
    height: 16px;
    margin-left: var(--space-02);
    fill: var(--text-contrast-brand);
    cursor: pointer;
    margin-top: -4px;
  }

  .helper-hover-text-size {
    font-size: 13px;
  }

  .help-center-text-color {
    color: var(--text-contrast-brand);
    cursor: pointer;
  }
}
