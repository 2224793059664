.inline-notification-wrapper {
  display: flex;
  width: 100%;
  padding: var(--space-03) var(--space-06);
  align-items: flex-start;
  border-radius: var(--radius-02);
  background: var(--surface-background);
  outline-offset: -1px;
  position: relative;

  .inline-notification-icon-container {
    display: flex;
    padding: var(--space-03) var(--space-01) var(--space-03) var(--space-03);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    .inline-notification-icon {
      height: 28px;
      width: 28px;
    }
  }

  .inline-notification-description {
    display: flex;
    padding: var(--space-05) 0px var(--space-05) var(--space-06);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--space-02);
    flex: 1 0 0;
    color: var(--text-key);
    word-break: break-word;
  }

  .rectangle {
    width: 8px;
    height: 100%;
    position: absolute;
    border-radius: var(--radius-02) 0 0 var(--radius-02);
    top: 0;
    left: 0;
  }

  &.info {
    outline: var(--border-01) solid var(--outline-contrast-brand);

    .inline-notification-icon-container {
      .inline-notification-icon {
        fill: var(--object-contrast-brand);
      }
    }

    .rectangle {
      background: var(--surface-contrast-brand);
    }
  }

  &.success {
    outline: var(--border-01) solid var(--outline-contrast-success);

    .inline-notification-icon-container {
      .inline-notification-icon {
        fill: var(--object-contrast-success);
      }
    }

    .rectangle {
      background: var(--surface-contrast-success);
    }
  }

  &.warning {
    outline: var(--border-01) solid var(--outline-contrast-warning);

    .inline-notification-icon-container {
      .inline-notification-icon {
        fill: var(--object-contrast-warning);
      }
    }

    .rectangle {
      background: var(--surface-contrast-warning);
    }
  }

  &.error {
    outline: var(--border-01) solid var(--outline-contrast-danger);

    .inline-notification-icon-container {
      .inline-notification-icon {
        fill: var(--object-contrast-danger);
      }
    }

    .rectangle {
      background: var(--surface-contrast-danger);
    }
  }
}
