.time-picker-container {
  width: 100%;
  height: 44px;
  display: flex;
  flex: 1;
}

.time-picker-wrapper {
  position: absolute;
  border-radius: var(--radius-02);
  border: var(--border-01) solid var(--outline-secondary);
  background: var(--surface-panel);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 192px;
  left: 0;
  width: 100%;
  color: var(--text-color);
  top: 52px;
  overflow: hidden;
  padding: var(--space-06);
  z-index: 1;
  .time-picker-side {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    .time-picker-select {
      text-align: center;
      font-size: 12px;
      padding: 5px;
      border-radius: 12px;
      cursor: pointer;
      &:hover {
        background-color: var(--accent-03-a) !important;
      }
    }
  }
}

.time-picker-gray {
  .time-picker-wrapper {
    background-color: #eceef2;
  }
}

.time-picker-text {
  display: flex;
  align-items: center;
  color: var(--text-key);
  font-size: 16px;
  font-weight: normal;

  input.time-picker-numbers {
    max-width: 44px;
    padding: 0 !important;
    color: var(--text-key);
  }
}
.DateInput__disabled,
.DateInput_input__disabled {
  background-color: transparent;
}

.SingleDatePicker.SingleDatePicker_1 {
  width: 100%;
}
