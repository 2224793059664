.radio-btn-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-btn {
  display: flex;
  align-items: center;
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.radio-btn-margin {
  margin-top: var(--space-06);
}

.radio-btn:disabled {
  cursor: not-allowed;
}

.radio-btn input {
  display: none;
}

.radio-btn-indicator {
  height: 20px;
  width: 20px;
  background: transparent;
  border: none;
  border-radius: 50%;
  outline: var(--border-01) solid var(--outline-secondary);
  outline-offset: calc(-1 * var(--border-01));
}

.radio-btn input:hover:enabled ~ .radio-btn-indicator {
  background-color: var(--surface-hover);
}
.radio-btn input:active:enabled ~ .radio-btn-indicator {
  background-color: var(--surface-active);
}
.radio-btn input:disabled ~ .radio-btn-indicator {
  background-color: var(--surface-secondary);
  cursor: not-allowed;
}

.radio-btn input:checked ~ .radio-btn-indicator {
  background-color: var(--surface-contrast-brand);
  outline-color: transparent;
}
.radio-btn input:checked:hover:enabled ~ .radio-btn-indicator {
  background-color: var(--surface-contrast-hover-brand);
}
.radio-btn input:checked:active:enabled ~ .radio-btn-indicator {
  background-color: var(--surface-contrast-active-brand);
}
.radio-btn input:checked:disabled ~ .radio-btn-indicator {
  background-color: var(--surface-tertiary);
  cursor: not-allowed;
}
.radio-btn input:checked ~ .radio-btn-indicator::after {
  display: block;
}
.radio-btn input:checked:disabled ~ .radio-btn-indicator::after {
  background-color: var(--object-secondary);
}

.radio-btn-indicator::after {
  content: "";
  position: absolute;
  display: none;
  height: 8px;
  width: 8px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: var(--object-invert);
}
