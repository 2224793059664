.input-button-wrapper {
  width: 28px;
  height: 28px;
  position: relative;

  .input-button {
    padding: var(--space-02);
    border: none;
    border-radius: var(--radius-01);
    background-color: transparent;
    cursor: pointer;

    .input-button-icon {
      display: flex;
      height: 20px;
      width: auto;
      fill: var(--object-primary);
    }
    .input-button-icon-danger {
      fill: var(--object-contrast-danger);
    }

    &:hover:enabled {
      .input-button-icon {
        fill: var(--object-key);
      }
      .input-button-icon-danger {
        fill: var(--object-contrast-danger);
      }
    }
    &:active:enabled {
      background-color: var(--surface-contrast-alpha-brand);

      .input-button-icon {
        fill: var(--object-key);
      }
      .input-button-icon-danger {
        fill: var(--object-contrast-danger);
      }
    }
    &:disabled {
      cursor: not-allowed;

      .input-button-icon {
        fill: var(--object-tertiary);
      }
      .input-button-icon-danger {
        fill: var(--object-contrast-danger);
      }
    }

    &-active {
      background-color: var(--surface-contrast-alpha-brand);

      .input-button-icon {
        fill: var(--object-key);
      }
      .input-button-icon-danger {
        fill: var(--object-contrast-danger);
      }
    }
  }
}
