@import "./sizing.scss";

/* global */
/* ------ */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "Local Inter", sans-serif;
  -webkit-font-feature-settings: "case" 1, "cv11" 1, "cv06" 1, "cv05" 1;
  -moz-font-feature-settings: "case" 1, "cv11" 1, "cv06" 1, "cv05" 1;
  -ms-font-feature-settings: "case" 1, "cv11" 1, "cv06" 1, "cv05" 1;
  font-feature-settings: "case" 1, "cv11" 1, "cv06" 1, "cv05" 1;
  font-style: normal;
  font-size: var(--large-font-size);
  line-height: var(--large-line-height);
  font-weight: var(--large-font-weight);
  overflow: hidden;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none !important;
}

/* end global */

/* typography */
/* ---------- */
p,
h1,
h2,
h3 {
  margin: 0;
}

h1 {
  padding: 0;
  color: currentColor;
}

h2 {
  font-weight: 500;
  font-size: 22px;
}

h2.white {
  color: white;
}

h3.subtitle {
  font-size: 12px;
  font-weight: 500;
}

.subtitle.black {
  color: black;
}

.subtitle.f-size-16 {
  font-size: 16px;
}

.f-size-16 {
  font-size: 16px;
}

/* end typography */

/* width height style */
.flex-1 {
  flex: 1;
}

.w-8 {
  width: 8%;
}

.w-10 {
  width: 10%;
}

/* ------------------ */
.w-38 {
  width: 38%;
}

.w-62 {
  width: 62%;
}

/* end width height */

/* global elements style */
/* --------------------- */
html,
body {
  background-color: var(--surface-panel);
}

section {
  margin-top: 40px;
}

section.section--modifier {
  border-radius: 15px;
}

.padding-left-50 {
  padding-left: 50px !important;
}

.info-icon {
  width: 25px;
  height: 25px;
  box-sizing: content-box;
  padding: 5px;
}

.icon-circle {
  border-radius: 50px;
}

.padding-right {
  padding-right: 40px;
}

.padding-right--modifier {
  padding-right: 60px;
}

.subtitle-wrapper {
  padding: 48px 0;
  border-radius: 15px 15px 0 0;
  background-color: #282828;
  box-shadow: 0px 3px 6px #4a75a17a;
  position: relative;
}

.subtitle-wrapper.subtitle-campaigns--modifier {
  padding: 20px 0;
}

.subtitle-wrapper.subtitle-wrapper--modifier {
  padding: 32px 0;
}

input {
  background-color: transparent;
  border: 0;
}

a {
  color: currentColor;
}

a:hover {
  text-decoration: none;
  color: currentColor;
}

.wrapper-buttons {
  display: flex;
  max-width: 480px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--space-06);
  flex: 1 0 0;

  .connection {
    display: flex;
    max-width: 480px;
    align-items: center;
    gap: var(--space-06);
    align-self: stretch;
  }
}

.wrapper-buttons.buttons--modifier {
  margin: 0;
  padding: 25px 26px;
}

.user-info {
  position: absolute;
  top: 3.8%;
  right: 3.8%;
}

.user-info svg {
  width: 20px;
  height: 20px;
}

.not-yet {
  padding-top: 32px !important;
}

.dark-text {
  color: #232b66;
}

body#app-content-body.theme-1
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-2
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-1 .input-range-critical .input-range__slider,
body#app-content-body.theme-2 .input-range-critical .input-range__slider {
  background-color: var(--error-color) !important;
}

body#app-content-body.theme-1 .input-range-critical .input-range__track--active,
body#app-content-body.theme-2 .input-range-critical .input-range__track--active {
  background: var(--error-color) !important;
}

body#app-content-body .input-range-warmup .input-range__track--active,
body#app-content-body
  .input-range-warmup
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body .input-range-warmup .input-range__slider {
  background: var(--warning-color) !important;
}

/* Subtitle list */
/* -------- */
.date-row {
  min-width: 11%;
}

.name-row {
  min-width: 30%;
}

.date-row.modifier {
  min-width: 40%;
  display: flex;
}

.w-20 {
  min-width: 20% !important;
}

.w-40 {
  min-width: 40% !important;
}

.w-60 {
  min-width: 60% !important;
}

.icons-rows {
  width: 30%;
}

.icons-rows.modifier {
  min-width: 40%;
}

.color {
  color: #1b1d48 !important;
}

.label {
  font-size: 12px;
}

.icons-rows .check {
  color: #1c1b1b;
}

.icons-rows .times {
  color: #bdbdbd;
}

.icons-rows span {
  min-width: 25%;
}

.icons-rows li {
  min-width: 25%;
  position: relative;
}

.icons-rows li:hover .desc-text {
  opacity: 1;
}

.icons-rows li .desc-text {
  min-width: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.6s;
  transform: translateY(-100%);
  border: 1px solid #282828;
}

.text-rows {
  width: 59%;
}

.text-rows.modifier {
  width: 60%;
}

.text-rows.modifier1 {
  width: 60%;
}

.text-rows.modifier li {
  width: 25%;
}

.text-rows.modifier1 li {
  min-width: 25%;
}

.text-rows li {
  width: 16.5%;
  padding: 0 4px;
}

.radius-50 {
  border-radius: 50px;
}

.circle {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}

// .dark-bg {
//   background-color: #7282a0;
//   padding: 8px 20px;
// } #to_be_deleted
.light-bg {
  background-color: #e0e0e0;
  padding: 10px;
}

.wrp-campaigns-list {
  border-radius: 0 0 15px 15px;
}

.campaigns-list {
  border-bottom: 1px solid rgba(128, 128, 128, 0.46);
}

.campaigns-list:last-child {
  border-bottom: none;
  /* padding-bottom: 40px !important; */
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #ced4da;
  background: #fff;
}

.crisp-client {
  z-index: 5000;
  position: relative;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.dropdown-delete-button:hover {
  background-color: rgba(238, 5, 53, 0.15) !important;
}

.text-invert {
  color: var(--text-invert) !important;
}

.hook-tooltip-text {
  word-break: break-word;
}

.hook-tooltip {
  display: inline-flex;
  min-width: 32px;
  max-width: 256px;
  padding: var(--space-04) var(--space-06);
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-02);
  background: var(--surface-key);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  cursor: pointer;
  z-index: 11002;
  // transition: 1s ;

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 6px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    border-top-color: var(--surface-key);
  }

  .caret-bottom {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(0deg);
  }

  .caret-top {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  .caret-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: -8px;
  }

  .caret-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(270deg);
    right: -8px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-top-color: var(--surface-key);
}

.tooltip-image-personalization {
  position: absolute;
  transition: 0.4s;
  border-radius: 5px;
  background-color: var(--on-surface-color);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 4px;
  color: var(--text-colorTooltip);
  top: 0;
  width: 266px;
  left: -24px;
  transform: translateY(-110%);
  display: none;
}

.wrp-tooltip button:hover + .tooltip-image-personalization,
.wrp-tooltip .form-group:hover + .tooltip-image-personalization {
  display: block;
  opacity: 1;
}

.search-icon {
  width: 30px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.logout-link {
  color: var(--text-color);
  cursor: pointer;
}

svg.back-lead-icon {
  width: 40px;
  height: 40px;
}

.timezone-picker {
  height: 38px;
}

.timezone-picker input {
  padding: 0 !important;
  border: 0 !important;
}

.form label {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  border: 0;
  width: 100%;
  height: max-content;
}

button.btn .button-text {
  font-weight: 400 !important;
}

.create-tag-icon-selected {
  display: flex;
  width: 12px;
  height: 12px;
  fill: #fff;
}

.add-image-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.delete-image-template {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  background: var(--accent-03);
  cursor: pointer;
}

.template-image-item:hover ~ .delete-image-template,
.delete-image-template:hover {
  display: flex;
}

.icon-step-close-image {
  width: 9px;
  height: 9px;
  color: white;
}

.delete-item-bin-container {
  color: #eb2158;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.font-semi-bold {
  font-weight: 500;
}

.step-2-campaigns {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}

.create-campaign-info-buttons {
  display: flex;
  justify-content: flex-end;
}

.wrp-tooltip {
  position: relative;
}

.html-message-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: unset;
}

.email-provider-sub-title {
  padding-left: 2rem;
}

.email-provider-connect-wrapper .email-integrations-wrapper .email-integration-wrapper {
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.email-provider-connect-wrapper .email-integration-wrapper .btn.btn-danger {
  width: 181px;
}

.link-to-email-settings {
  margin: 0 auto 0 10%;
}

.helper-tooltip {
  position: relative;
  display: inline-block;

  .helper-hover-text {
    display: none;
  }
}

.helper-tooltip:hover {
  .helper-hover-text {
    display: inline-block;
    z-index: 12000;
  }
}

.helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 135px;
  background-color: var(--on-surface-color);
  color: var(--text-colorTooltip);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1100;
  bottom: 115%;
  left: 60%;
  margin-left: -67px;
  font-size: 13px;
}

.helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--on-surface-color) transparent transparent transparent;
}

.helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 225px;
  background-color: #ffffff;
  color: #080808;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -84px;
  left: 16%;
  margin-left: -67px;
  font-size: 13px;
  border: 1px solid #e1eaf5;
  text-align: start;
  padding: 9px;
  cursor: pointer;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text::before {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 109px;
  border-width: 9px 9px 0px 9px;
  border-style: solid;
  border-color: #e1eaf5 transparent;
}

.white-helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 85px;
  left: 113px;
  margin-left: -3px;
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.ql-container.ql-snow .ql-tooltip {
  left: 0 !important;
  right: unset !important;
  background-color: var(--surface-primary);
  color: var(--text-color);

  input {
    color: var(--text-color);
  }
}

.noselect-text {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  font-size: 34px;
  line-height: 42px;
  font-weight: 500;
}

h1 + p,
.paragraphUnderH1 {
  margin-top: 8px;
}

.font-weight-light {
  color: var(--text-color);
}

.changing-theme-fade *:not(.dark-theme-switch-overlay) {
  transition: background-color 0.7s linear !important;
}

.InputElement {
  color: var(--text-secondary);
}

.input-on-background {
  .input-group-labels,
  .form-input-maxlength {
    color: var(--text-color);
  }

  input::placeholder {
    color: var(--text-secondary);
  }

  // InputElement is-empty Input Input--empty

  .input-group {
    background-color: var(--input-on-background);
    // background-color: var(--surface-color);
  }

  .form-control {
    background-color: transparent;
    color: var(--text-color);

    &::placeholder {
      color: var(--text-secondary);
    }
  }
}

.form-control-on-background {
  border-color: var(--border-color);
  background-color: var(--surface-primary);
  color: var(--text-color);

  &::placeholder {
    color: var(--text-secondary);
  }

  &:focus {
    color: var(--text-color);
  }
}

.form-control-on-surface {
  background-color: var(--surface-primary);
  color: var(--text-color);
  border: 1px solid var(--border-color);

  &::placeholder {
    color: var(--text-secondary);
  }

  &:focus {
    color: var(--text-color);
  }
}

.stripe-input-background {
  .input-group {
    height: 44px;
    background-color: var(--stripe-input-background) !important;
  }
}

.input-on-surface {
  .input-group-labels,
  .form-input-maxlength {
    color: var(--text-color);
  }

  .input-group {
    background-color: var(--surface-primary) !important;
  }

  .form-control {
    color: var(--text-color);

    input {
      color: var(--text-color);
    }

    &::placeholder {
      color: var(--text-secondary);
    }
  }
}

.form label {
  color: var(--text-color);
}

.no-cursor {
  cursor: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-color);
}

.emoji-mart {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  border-color: var(--border-color) !important;

  #emoji-mart-search-1 {
    border-color: var(--border-color) !important;
    color: var(--text-color) !important;
  }

  .emoji-mart-category-label span {
    background-color: var(--background-color);
  }

  .emoji-mart-search-icon {
    color: var(--text-color) !important;
  }
}

.new-tabs-design {
  .nav-tabs {
    border: unset;
  }

  .nav-link {
    color: var(--text-color);
    font-weight: 500;
    padding: 0.5rem 1rem;
    margin-right: 1rem;

    &.active {
      border-color: transparent;
    }

    &:not(.active):hover {
      border-color: transparent;
    }
  }
}

// fix for bootstraps css
.btn.disabled,
.btn:disabled {
  opacity: 1 !important;
}

.input-calendar-icon {
  width: 20px;
  height: 20px;
  fill: var(--object-primary);
}

.calendar-arrow-icon {
  width: 20px;
  height: 20px;
  margin: 0px var(--space-04);
}

.DateRangePicker .DateRangePickerInput:has(.DateInput_input__readOnly) {
  background-color: transparent !important;
  outline-offset: calc(-1 * var(--border-01));
  outline: var(--border-01) solid var(--outline-tertiary) !important;

  .input-calendar-icon {
    fill: var(--object-secondary);
  }
}

.DateRangePicker .DateRangePickerInput:has(.DateInput__disabled) {
  outline-offset: calc(-1 * var(--border-01));
  outline: var(--border-01) solid var(--outline-tertiary) !important;

  .input-calendar-icon {
    fill: var(--object-tertiary);
  }

  .calendar-arrow-icon {
    fill: var(--object-tertiary);
  }
}

.DateRangePickerInput {
  color: var(--text-color) !important;
  background: var(--surface-primary) !important;
  background-color: var(--surface-primary) !important;

  .calendar-arrow-icon {
    fill: var(--object-secondary);
  }

  .DateInput {
    width: 84px;

    &_input {
      background: var(--surface-primary) !important;

      &__disabled {
        background-color: transparent !important;
        font-style: normal;
        color: var(--text-secondary) !important;
      }

      &__readOnly {
        background-color: transparent !important;
      }
    }
  }

  &__disabled {
    background-color: transparent !important;
    font-style: normal;
  }

  &__readOnly {
    background-color: transparent !important;
  }
}

.DateInput_input {
  border-bottom: 0px !important;
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  z-index: 10000 !important;
}

// .DateRangePicker .DateRangePickerInput__withBorder:hover {
//   border: none !important;
// }

.DateRangePickerInput,
.SingleDatePickerInput {
  .CalendarDay {
    border: none;

    &:hover {
      border: none !important;
    }
  }
}



.rotate-accordion-icon {
  transform: rotate(270deg) !important;
}

.rotate-accordion-icon-top {
  transform: rotate(90deg) !important;
}

.follow-instructions {
  text-decoration: underline;
}

.follow-instructions:hover {
  text-decoration: underline;
}

body#app-content-body.theme-1 .light-blue-bg {
  background-color: #d2e6ff !important;
}

.form-control,
.form-control:focus {
  background-color: transparent;
  color: var(--text-color);

  &::placeholder {
    color: var(--text-secondary);
  }

  &:focus-within {
    border-color: transparent;
  }
}

.campaign-regular-layout,
.webhook-page-regular-layout {
  padding-bottom: 0 !important;

  .round-icon-button {
    border-color: #fff;
  }
}

.create-campaign-icon {
  width: 28px !important;
  height: 28px !important;
  padding: 4px !important;
  fill: #fff;
}

label {
  margin-bottom: 0;
}

.template-modal-buttons {
  display: flex;
  justify-content: space-evenly;
}

.create-sequence {
  display: flex;

  &__card {
    text-align: center;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    width: 400px;
    margin: 20px;
    padding-bottom: 20px;
  }

  &__icon {
    width: 160px;
    height: 150px;
    margin: 20px 0;
  }

  &__button {
    margin: 0 auto 15px auto !important;
    border-radius: 10px !important;
  }

  &__text {
    color: var(--text-color);
  }
}

.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

.nav-link-sales-templates {
  padding-bottom: 20px;
}

.nav-link-sales-templates .nav-tabs {
  border: 0;
}

.nav-link-sales-templates .nav-link {
  padding: 0.1rem 0;
  margin-right: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.nav-link-sales-templates .nav-link.active {
  border-color: transparent;
  padding: 0.1rem 0;
  background-color: transparent;
}

.nav-link-sales-templates .nav-link:not(.active):hover {
  border-color: transparent;
}

.title-dropdown-menu.dropdown-menu {
  width: 100%;
  z-index: 10000;
}

.Toastify__toast-container {
  z-index: 99999;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  bottom: 3.5rem;
}

.Toastify__toast {
  padding: 10px;
}

.tooltipText {
  opacity: 1 !important;
}

.campaign-tooltip {
  position: relative;
  display: inline-block;
  pointer-events: all;
  width: 45px;
}

/* Tooltip text */
.campaign-tooltip .tooltipText {
  visibility: hidden;
  width: 306px;
  background-color: var(--on-surface-color);
  color: var(--background-color);
  padding: 5px 5px;
  border-radius: 6px;
  bottom: 40px;
  left: 62%;
  margin-left: -155px;
  text-align: start;
  font-size: 12px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.campaign-tooltip:hover .tooltipText {
  visibility: visible;
}

.campaign-tooltip .tooltipText::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: var(--on-surface-color) transparent transparent transparent;
}

.dark-tooltip-bottom-caret::after {
  border-top: 11px solid var(--on-surface-color) !important;
}

.light-tooltip-bottom-caret {
  bottom: -9px;
}

.light-tooltip-bottom-caret::after {
  border-top: 11px solid var(--surface-color) !important;
}

.light-tooltip-bottom-caret::before {
  bottom: -4px;
  border-top: 12px solid var(--border-color) !important;
}

/* The container */
.checkmark-container {
  margin-right: 40px;
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border-radius: 50%;
  margin-top: 3px;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: var(--surface-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmark-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.no-teams-found {
  color: var(--text-color);
}

.invalid {
  color: #fff;
}

.close {
  text-shadow: none !important;
  opacity: 1 !important;
  margin-left: 5px;
}

.button-loader {
  display: flex;

  .btn-text {
    margin-left: 10px;
    width: max-content;
    color: #fff;
    display: flex;
    align-items: center;
  }

  .btn-text:hover {
    color: #fff;
  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 7px;
  padding: 1rem;
  color: var(--text-color);
  z-index: 1;

  &__icon {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
  }
}

.dropdown-menu-title {
  font-size: 22px;
  text-align: center;
  display: block;
  padding: 5px 0 10px 0;
  margin-bottom: 20px;
}

.wrapper-timezone {
  display: flex;
  margin-bottom: var(--space-08);

  .timezone-picker-seat-settings {
    ul {
      border: 0;
      width: 100%;
    }

    input {
      border: none;
      font-size: 16px;
      font-family: "Inter", sans-serif;
      padding: 0;
      color: var(--text-color) !important;
      background-color: transparent !important;
    }
  }

  .timezone-info-box {
    font-size: 15px;
  }
}

.timezone-picker-seat-settings ul {
  margin-top: 12px !important;
  width: calc(100% + 20px) !important;
  left: -10px !important;
}

.timezone-picker-seat-settings ul,
.timezone-picker-seat-settings li button {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.no-active-accounts {
  text-align: center;
  font-size: 14px;

  &__text {
    text-align: justify;
    padding-bottom: 30px;
  }

  &__image {
    width: 160px;
    height: auto;
  }

  &__emphasized {
    font-style: italic;
    font-weight: bold;
  }
}

.modal-open {
  .header-container {
    position: static;
  }
}

.info-box-caret {
  top: auto !important;
}

.truncate-text-1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.truncate-text-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  white-space: normal;
}

.truncate-single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.os-scrollbar-handle {
  background: var(--object-scroll) !important;
}

.Toastify__toast-container--bottom-right {
  right: 7em !important;
}

.Toastify__toast {
  display: flex !important;
  width: 400px;
  min-width: 320px;
  max-width: 400px;
  min-height: 52px;
  padding: var(--space-03) var(--space-06) !important;
  align-items: flex-start;
  gap: var(--space-06) !important;
  border-radius: var(--radius-02) !important;
  background: var(--surface-panel) !important;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5) !important;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 8px;
  }

  &.Toastify__toast--success {
    border: var(--border-01) solid var(--outline-contrast-success);

    &::after {
      background: var(--surface-contrast-success);
    }
  }

  &.Toastify__toast--info {
    border: var(--border-01) solid var(--outline-contrast-brand);

    &::after {
      background: var(--surface-contrast-brand);
    }
  }

  &.Toastify__toast--warning {
    border: var(--border-01) solid var(--outline-contrast-warning);

    &::after {
      background: var(--surface-contrast-warning);
    }
  }

  &.Toastify__toast--error {
    border: var(--border-01) solid var(--outline-contrast-danger);

    &::after {
      background: var(--surface-contrast-danger);
    }
  }
}

.Toastify__toast-body {
  @extend .large;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  word-break: break-word;
  margin-left: var(--space-07) !important;
}

.Toastify__close-button {
  color: var(--object-key) !important;
  height: 20px;
  width: 20px;
  margin: auto;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.menu-wrapper {
  display: flex;
  padding: var(--space-05);
  justify-content: center;
  align-items: center;
}

.icon-dots-vertical {
  fill: var(--object-key);
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.illustration-svg-empty-table {
  width: 320px;
  height: 192px;
}

.custom-menu {
  width: 240px;
  float: left;
  display: flex;
  padding: var(--space-06);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--space-02);
  border-radius: var(--radius-02);
  border: var(--border-01) solid var(--outline-secondary);
  background: var(--surface-panel);
  z-index: 100001;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5);

  li {
    cursor: pointer;

    &:last-of-type:hover {
      background-color: var(--danger-button-border);
    }
  }

  .campaign-delete-option-custom-menu {
    .menu-item-label {
      color: var(--text-contrast-danger);
    }

    .menu-item-icon {
      fill: var(--object-contrast-danger);
    }
  }
}

.text-invert-footer {
  background: var(--text-invert) !important;
}

.dropdown-menu {
  border-radius: var(--radius-02);
  border: var(--border-01) solid var(--outline-secondary);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.5) !important;

  &::after {
    border-bottom-color: var(--surface-panel) !important;
    display: none;
  }
}

// Move zendesk launcher
iframe#launcher {
  z-index: 10000000 !important;
}

::-webkit-scrollbar {
  width: 10px;
  background: var(--surface-background);
}

::-webkit-scrollbar-thumb {
  border: 2px solid var(--surface-background);
  background-clip: padding-box;
  border-radius: 9999px;
  background: var(--object-scroll);
}
