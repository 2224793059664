.info-modal-wrapper {
  display: block;

  .modal-dialog {
    margin: auto;
    padding-top: 40px;
  }

  .modal-content {
    background: var(--background-color) 0% 0% no-repeat padding-box;
    color: var(--text-color);
    box-shadow: 0 0 25px #00000029;
    border-radius: 20px;
    border: 0;
  }

  .modal-header {
    padding: 32px 48px;
    border-radius: 20px 20px 0 0;
    border-color: var(--border-color);

    .close-button {
      position: absolute;
      top: 24px;
      right: 20px;
      width: 12px;
      height: 12px;

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .close-btn-color {
      color: var(--text-color);
    }

    .close-btn-error-color {
      color: var(--background-color);
    }
  }

  .modal-body {
    display: flex;
    padding: 32px 48px;
    align-items: center;
    word-break: break-word;

    .timezone-picker,
    .rc-time-picker {
      margin-top: 0;
      margin-bottom: 0;
    }

    select,
    .rc-time-picker {
      -webkit-appearance: none;
    }

    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .modal-footer {
    border: 0;
    padding: 16px 48px 32px 48px;
  }

  .modal-basic {
    background-color: transparent;
  }

  .modal-info {
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .modal-warning {
    color: var(--text-color);
  }

  .info-modal-icon.modal-warning {
    width: 40px !important;
    height: 40px !important;
    margin-right: 16px !important;
    margin-left: 0 !important;
    padding: 0;
  }

  .modal-success {
    background-color: #09ab09;
    color: var(--background-color);
  }

  .modal-error {
    background-color: #b30000;
    color: var(--background-color);
  }

  .info-modal-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 6px;
    fill: var(--background-color);
    margin-right: 10px;
    margin-left: 40px;

    &.modal-info {
      background-color: var(--accent-03);
    }
  }
}

.filter-out-modal {
  .modal-dialog {
    max-width: 50vw;

    .modal-content {
      width: 50vw;

      .button-wrapper {
        justify-content: space-evenly;
      }
    }
  }
}

.pricing-tiers-modal {
  .modal-dialog {
    max-width: 1200px;

    .new-tabs-design {
      & > p,
      & > span {
        font-weight: normal;
        color: var(--text-color);
      }

      & > p {
        font-size: 24px;
      }

      & > span {
        font-size: 14px;

        & > span {
          color: var(--accent-03);
          cursor: pointer;
        }
      }

      & > .pricing-tier-cards {
        display: flex;
        justify-content: space-between;
        margin: 32px auto 36px;
      }
    }

    .modal-footer {
      margin: auto;
      max-width: 500px;

      .btn.primary-bg:disabled,
      .btn.primary-bg[disabled] {
        cursor: not-allowed;
        filter: brightness(1.3);
      }
    }

    .step-progress-wrapper {
      margin: 0 auto 80px;
      width: 600px;
    }

    .two-step-progress {
      .step-progress-wrapper {
        margin: 0 auto 80px;
        width: 250px;
      }
    }
  }
}

.company-info-modal {
  .modal-dialog {
    max-width: 760px;

    .step-progress-wrapper {
      margin: 0 auto 80px;
      width: 600px;
    }

    .new-tabs-design {
      & > {
        .row {
          margin-top: -0.5rem;
          margin-bottom: -0.7rem;
        }
      }
    }
  }
}

.seat-info-modal {
  .modal-dialog {
    max-width: 800px;
  }

  .step-progress-wrapper {
    margin: 0 auto 80px;
    width: 404px;
  }

  .two-step-progress {
    .step-progress-wrapper {
      margin: 0 auto 80px;
      width: 250px;
    }
  }
}

.email-provider-connected-modal {
  .modal-dialog {
    padding-top: 150px;
    max-width: 1055px;
  }
}

.payment-modal {
  .modal-dialog {
    max-width: 760px;
  }

  .form-label,
  .accordion-button,
  p {
    font-weight: 500 !important;
  }

  .two-step-progress {
    .step-progress-wrapper {
      margin: 0 auto 80px;
      width: 250px;
    }
  }

  .pricing-notice {
    display: block;
    width: 100%;
    padding-top: 5px;
    color: var(--placeholder-color);
    font-size: 10px;
    text-align: center;
  }
}

.payment-success {
  .payment-success-body {
    display: flex;
    flex-direction: column;

    p {
      text-align: center;
    }

    img {
      object-fit: contain;
      object-position: center;
      margin: 32px auto;
    }
  }

  button.btn.transparent-btn {
    color: var(--accent-03) !important;
  }

  .button-text {
    font-size: 18px !important;
  }
}

.subscription-modal {
  p {
    font-weight: normal;
  }
}

.billing-modal {
  p,
  label {
    color: var(--text-color) !important;
    font-weight: normal !important;
  }

  .modal-footer {
    padding-top: 2rem;
  }
}

.change-subscription-card-modal {
  .modal-footer {
    padding: 1rem 4rem;
  }
}

.change-company-info-modal {
  .modal-dialog {
    max-width: 760px;
  }

  .modal-footer {
    padding: 1rem 4rem;
  }
}

.notification-emoji-modal {
  .modal-body {
    padding: 0 !important;

    & > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 2rem 3rem;
    }

    span {
      img {
        margin-bottom: 16px;
      }
    }

    .close-button {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background-color: var(--accent-03);
      color: white;
      cursor: pointer;
    }
  }
}

.add-new-leads {
  .custom-file-label {
    max-height: 100%;
  }
}

.transfer-seats-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upgrade-pricing-tier-modal {
  .modal-body {
    & > span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.add-account-step2-modal {
  .modal-dialog {
    max-width: 550px;
  }
}

.add-account-step3-modal {
  .modal-dialog {
    max-width: 550px;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .linkedin-subscription,
  .input-group-wrapper {
    margin-bottom: 1.5rem !important;
  }
}

.add-account-step4-modal {
  .modal-dialog {
    max-width: 550px;
  }
}

.email-integration-modal {
  .modal-dialog {
    max-width: 95%;
  }
}

.download-invoices-modal {
  .modal-dialog {
    max-width: 1100px;
  }
}

.back-lead-to-campaign-modal {
  .modal-dialog {
    max-width: 90%;
  }
}

.filter-leads-by-status-modal {
  .modal-dialog {
    max-width: 1100px;
  }
}

.suggested-templates-modal {
  .modal-dialog {
    max-width: 900px;
  }
}

.remove-team-template {
  .modal-dialog {
    max-width: 600px;
  }
}

.end-free-trial-modal {
  .modal-dialog {
    max-width: 600px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.maxZIndex {
  z-index: 1050;
}

.warning-modal {
  .modal-dialog {
    max-width: 600px;
    height: 100%;
    display: flex;
    align-items: center;

    .info-modal-icon {
      width: 48px;
      height: 48px;
      margin: -15px 0 0 0;
    }

    p {
      margin-left: 21px;
    }

    .justify-buttons {
      margin: 0 50px 0 50px;
    }
    span svg {
      height: 40px;
      width: 40px;
      margin: -15px 20px 0 0;
    }
  }
}

.linkedin-login {
  .modal-dialog {
    max-width: 1400px;
  }
}

.preview-modal {
  .w-100 {
    height: 100%;
  }
  .modal-body {
    align-items: flex-start;
  }
  .modal-footer {
    padding: 0;
  }
}
