@mixin tab-after($border-color) {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--border-02);
  border-bottom: var(--border-02) solid $border-color;
  border-radius: 0;
}

@mixin label-color($color) {
  color: $color;
}

.tab-wrapper {
  display: inline-flex;
  align-items: center;
  padding: var(--space-05) var(--space-06);
  cursor: pointer;
  position: relative;

  &__label {
    @include label-color(var(--text-primary));
  }

  &::after {
    @include tab-after(transparent);
  }

  &__active-tab::after {
    @include tab-after(var(--outline-contrast-brand));
  }

  &:hover::after {
    @include tab-after(var(--outline-tertiary));
  }

  &__active-tab:hover::after {
    @include tab-after(var(--outline-contrast-brand));
  }

  &:active::after {
    @include tab-after(var(--outline-contrast-brand));
  }

  &__active-tab:hover:active::after {
    @include tab-after(var(--outline-contrast-brand));
  }

  &:hover &__label,
  &:active &__label,
  &__active-tab &__label,
  &__active-tab:hover &__label,
  &__active-tab:hover:active &__label {
    @include label-color(var(--text-key));
  }

  &__disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
