:root {
  /* TODO MJ@Any: Delete this after horizon is finished! */
  --chat-message-footer-color: rgba(225, 231, 241, 0.2);
}

:root .dark-theme {

  /*================ SURFACE ================*/
  --surface-background: var(--black);
  --surface-panel: var(--gray-120);
  --surface-navigation: var(--gray-110);
  --surface-primary: var(--gray-110);
  --surface-secondary: var(--gray-100);
  --surface-tertiary: var(--gray-090);
  --surface-key: var(--white);
  --surface-hover: var(--white-a);
  --surface-active: var(--white-b);
  --surface-scrim: var(--black-s);
  --surface-scrim-invert: var(--black-s);
  --surface-contrast-brand: var(--accent-01);
  --surface-contrast-success: var(--green-01);
  --surface-contrast-warning: var(--yellow-01);
  --surface-contrast-danger: var(--red-01);
  --surface-contrast-neutral: var(--gray-030);
  --surface-contrast-alpha-brand: var(--accent-01-a);
  --surface-contrast-alpha-success: var(--green-01-a);
  --surface-contrast-alpha-warning: var(--yellow-01-a);
  --surface-contrast-alpha-danger: var(--red-01-a);
  --surface-contrast-alpha-neutral: var(--gray-030-a);
  --surface-contrast-hover-brand: var(--accent-02);
  --surface-contrast-hover-danger: var(--red-02);
  --surface-contrast-hover-neutral: var(--gray-040);
  --surface-contrast-active-brand: var(--accent-03);
  --surface-contrast-active-danger: var(--red-03);
  --surface-contrast-active-neutral: var(--gray-050);
  --surface-data-01: var(--lapis-01);
  --surface-data-02: var(--bronze-01);
  --surface-data-03: var(--jade-01);
  --surface-data-04: var(--violet-01);
  --surface-data-05: var(--chestnut-01);
  --surface-data-06: var(--maroon-01);
  --surface-data-07: var(--lagoon-01);
  --surface-data-08: var(--blush-01);
  --surface-data-09: var(--quartz-01);
  --surface-data-10: var(--slate-01);
  --surface-illustration-01: var(--acent-01);
  --surface-illustration-alpha-01: var(--accent-01-a);
  --surface-illustration-02: var(--accent-02);
  --surface-illustration-03: var(--accent-03);
  --surface-illustration-04: var(--accent-04);
  --surface-illustration-05: var(--accent-05);
  --surface-illustration-06: var(--white);
  /*================ end of SURFACE ================*/

  /*================ TEXT ================*/
  --text-primary: var(--gray-060);
  --text-secondary: var(--gray-070);
  --text-tertiary: var(--gray-080);
  --text-invert: var(--black);
  --text-expose: var(--white);
  --text-key: var(--white);
  --text-contrast-brand: var(--accent-01);
  --text-contrast-success: var(--green-01);
  --text-contrast-warning: var(--yellow-01);
  --text-contrast-danger: var(--red-01);
  --text-data-01: var(--lapis-02);
  --text-data-02: var(--bronze-02);
  --text-data-03: var(--jade-02);
  --text-data-04: var(--violet-02);
  --text-data-05: var(--chestnut-02);
  --text-data-06: var(--maroon-02);
  --text-data-07: var(--lagoon-02);
  --text-data-08: var(--blush-02);
  --text-data-09: var(--quartz-02);
  --text-data-10: var(--slate-02);
  /*================ end of TEXT ================*/

  /*================ OBJECT ================*/
  --object-primary: var(--gray-060);
  --object-secondary: var(--gray-070);
  --object-tertiary: var(--gray-080);
  --object-invert: var(--black);
  --object-expose: var(--white);
  --object-key: var(--white);
  --object-scroll: var(--white-b);
  --object-contrast-brand: var(--accent-01);
  --object-contrast-success: var(--green-01);
  --object-contrast-warning: var(--yellow-01);
  --object-contrast-danger: var(--red-01);
  /*================ end of OBJECT ================*/

  /*================ OUTLINE ================*/
  --outline-primary: var(--gray-110);
  --outline-secondary: var(--gray-090);
  --outline-tertiary: var(--gray-070);
  --outline-key: var(--white);
  --outline-contrast-brand: var(--accent-01);
  --outline-contrast-success: var(--green-01);
  --outline-contrast-warning: var(--yellow-01);
  --outline-contrast-danger: var(--red-01);
  /*================ end of OUTLINE ================*/

  /* TODO MJ@Any: Delete this after horizon is finished! */
  --primary-color: #0080FF;
  --primary-pale-color: #415c7a;

  --on-secondary-color: var(--text-color);

  --background-color: #252525;
  --input-on-background: #2d2d2d;

  /* Skylead colors */
  /* 
  --input-on-background: #2e3f57;
  */
  --surface-color: #22272e;
  --on-surface-color: #e6f0ff;

  --text-color: #e6f0ff;
  --text-colorTooltip: #535465;

  --border-color: #40486d;
  --success-color: #3ec786;
  --error-color: #eb2158;
  --warning-color: #f5b555;

  --some-emails-connected: #f37047;

  --lead-list-discovered: #6989ae;
  --lead-list-discovered-color: #fff;
  --lead-list-connection-pending: var(--warning-color);
  --lead-list-connection-pending-color: #fff;
  --lead-list-connected-not-replied: #794f84;
  --lead-list-connected-not-replied-color: #fff;
  --lead-list-replied: var(--success-color);
  --lead-list-replied-color: #fff;

  --lead-list-replied-not-connected: #fbdcea;
  --lead-list-replied-not-connected-color: #e85297;

  --campaign-statistic-count-total-profile-views-background: #7884a6;
  --campaign-statistic-total-replies-background: #73848d;

  --table-rate-gray-background: #c9cad3;
  --table-rate-green-background: #dcfde1;

  --input-on-surface: var(--background-color);
  --placeholder-color: #98abc1;

  --statistics-table-last-row: #8b8781;
  --stripe-input-background: #fff;
  --light-caret-border-color: #fff;
  --leads-count-statistic: #52C462;

  --icon-active-color: #20c997;
  --icon-failed-color: #eb2158;

  --active-email-status: #fff;

  /* TODO: define --auth-background for both themes */
  /* --auth-background: red; */

  /* buttons */

  /* All HSL values are bounded to primary color and represented as difference from primary color. */

  /* Because all shades of HSL colors in files are calculated with subtraction, you can find negative values here. 
  In example, if we need brighter lightness that original color, subtraction of negative value actually gives addition.  */

  /*================ REGULAR ================*/
  --regular-button-background: #617081;
  --regular-button-hover: #4d5c6d;
  --regular-button-disabled: #465261;
  --regular-button-border: #ffffff;
  /*================ end of REGULAR ================*/

  /*================ DANGER ================*/
  --danger-button-background: #d94d69;
  --danger-button-hover: #c43552;
  --danger-button-disabled: #a5455d;
  --danger-button-border: #f9a4b6;
  /*================ end of DANGER ================*/
  /* END OF BUTTONS */
}

:root .light-theme {

  /*================ SURFACE ================*/
  --surface-background: var(--white);
  --surface-panel: var(--gray-010);
  --surface-navigation: var(--gray-020);
  --surface-primary: var(--gray-020);
  --surface-secondary: var(--gray-030);
  --surface-tertiary: var(--gray-050);
  --surface-key: var(--black);
  --surface-hover: var(--black-a);
  --surface-active: var(--black-b);
  --surface-scrim: var(--black-s);
  --surface-scrim-invert: var(--white-s);
  --surface-contrast-brand: var(--accent-03);
  --surface-contrast-success: var(--green-03);
  --surface-contrast-warning: var(--yellow-03);
  --surface-contrast-danger: var(--red-03);
  --surface-contrast-neutral: var(--gray-080);
  --surface-contrast-alpha-brand: var(--accent-03-a);
  --surface-contrast-alpha-success: var(--green-03-a);
  --surface-contrast-alpha-warning: var(--yellow-03-a);
  --surface-contrast-alpha-danger: var(--red-03-a);
  --surface-contrast-alpha-neutral: var(--gray-080-a);
  --surface-contrast-hover-brand: var(--accent-04);
  --surface-contrast-hover-danger: var(--red-04);
  --surface-contrast-hover-neutral: var(--gray-090);
  --surface-contrast-active-brand: var(--accent-05);
  --surface-contrast-active-danger: var(--red-05);
  --surface-contrast-active-neutral: var(--gray-100);
  --surface-data-01: var(--lapis-02);
  --surface-data-02: var(--bronze-02);
  --surface-data-03: var(--jade-02);
  --surface-data-04: var(--violet-02);
  --surface-data-05: var(--chestnut-02);
  --surface-data-06: var(--maroon-02);
  --surface-data-07: var(--lagoon-02);
  --surface-data-08: var(--blush-02);
  --surface-data-09: var(--quartz-02);
  --surface-data-10: var(--slate-02);
  --surface-illustration-01: var(--acent-01);
  --surface-illustration-alpha-01: var(--accent-03-a);
  --surface-illustration-02: var(--accent-02);
  --surface-illustration-03: var(--accent-03);
  --surface-illustration-04: var(--accent-04);
  --surface-illustration-05: var(--accent-05);
  --surface-illustration-06: var(--white);
  /*================ end of SURFACE ================*/

  /*================ TEXT ================*/
  --text-primary: var(--gray-070);
  --text-secondary: var(--gray-060);
  --text-tertiary: var(--gray-050);
  --text-invert: var(--white);
  --text-expose: var(--white);
  --text-key: var(--black);
  --text-contrast-brand: var(--accent-03);
  --text-contrast-success: var(--green-03);
  --text-contrast-warning: var(--yellow-03);
  --text-contrast-danger: var(--red-03);
  --text-data-01: var(--lapis-01);
  --text-data-02: var(--bronze-01);
  --text-data-03: var(--jade-01);
  --text-data-04: var(--violet-01);
  --text-data-05: var(--chestnut-01);
  --text-data-06: var(--maroon-01);
  --text-data-07: var(--lagoon-01);
  --text-data-08: var(--blush-01);
  --text-data-09: var(--quartz-01);
  --text-data-10: var(--slate-01);
  /*================ end of TEXT ================*/

  /*================ OBJECT ================*/
  --object-primary: var(--gray-070);
  --object-secondary: var(--gray-060);
  --object-tertiary: var(--gray-050);
  --object-invert: var(--white);
  --object-expose: var(--white);
  --object-key: var(--black);
  --object-scroll: var(--black-s);
  --object-contrast-brand: var(--accent-03);
  --object-contrast-success: var(--green-03);
  --object-contrast-warning: var(--yellow-03);
  --object-contrast-danger: var(--red-03);
  /*================ end of OBJECT ================*/

  /*================ OUTLINE ================*/
  --outline-primary: var(--gray-030);
  --outline-secondary: var(--gray-040);
  --outline-tertiary: var(--gray-050);
  --outline-key: var(--black);
  --outline-contrast-brand: var(--accent-03);
  --outline-contrast-success: var(--green-03);
  --outline-contrast-warning: var(--yellow-03);
  --outline-contrast-danger: var(--red-03);
  /*================ end of OUTLINE ================*/

  /* TODO MJ@Any: Delete this after horizon is finished! */
  --primary-color: #0080FF;
  --primary-pale-color: #d5eaff;
  --on-secondary-color: var(--accent-03);

  --surface-color: #fafaff;
  --on-surface-color: #535465;

  --background-color: #ffffff;
  --text-color: #535465;
  --text-colorTooltip: #ffffff;

  --border-color: #e1e7f1;
  --success-color: #09ab09;
  --error-color: #eb2158;
  --warning-color: #f5c414;

  --some-emails-connected: #f37047;

  --lead-list-discovered: #e1e7ef;
  --lead-list-discovered-color: #6989ae;

  --lead-list-connected-not-replied: #e4dce6;
  --lead-list-connected-not-replied-color: rgb(121, 79, 132);

  --lead-list-replied: #cff1e6;
  --lead-list-replied-color: rgb(19, 183, 132);

  --lead-list-connection-pending: #fbf0de;
  --lead-list-connection-pending-color: rgb(236, 178, 93);

  --lead-list-replied-not-connected: #fbdcea;
  --lead-list-replied-not-connected-color: #e85297;

  --campaign-statistic-count-total-profile-views-background: #f9f9f9;
  --campaign-statistic-total-replies-background: #dcfde1;

  --table-rate-gray-background: #f0f0f7;
  --table-rate-green-background: #dcfde1;

  --input-on-background: var(--surface-color); /* should be var(--input-on-surface) */

  --input-on-surface: var(--background-color);
  --placeholder-color: #98abc1;

  --statistics-table-last-row: #fffaf1;
  --stripe-input-background: var(--input-on-surface);
  --light-caret-border-color: #e1e7f1;
  --leads-count-statistic: #52C462;

  --icon-active-color: #20c997;
  --icon-failed-color: #eb2158;

  --active-email-status: var(--success-color);

  /* buttons */

  /* All HSL values are bounded to primary color and represented as difference from primary color. */

  /* Because all shades of HSL colors in files are calculated with subtraction, you can find negative values here. 
  In example, if we need brighter lightness that original color, subtraction of negative value actually gives addition.  */

  /*================ REGULAR ================*/
  --regular-button-background: #98abc1;
  --regular-button-hover: #7f8d9e;
  --regular-button-disabled: #b7c4d3;
  --regular-button-border: #cbdbeb;
  /*================ end of REGULAR ================*/

  /*================ DANGER ================*/
  --danger-button-background: #f02750;
  --danger-button-hover: #e10a36;
  --danger-button-disabled: #f46784;
  --danger-button-border: #fcb0bf;
  /*================ end of DANGER ================*/

  /* END OF BUTTONS */
}
